// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-email-form-js": () => import("/opt/build/repo/src/templates/email-form.js" /* webpackChunkName: "component---src-templates-email-form-js" */),
  "component---src-templates-facebook-link-js": () => import("/opt/build/repo/src/templates/facebook-link.js" /* webpackChunkName: "component---src-templates-facebook-link-js" */),
  "component---src-templates-flipping-card-js": () => import("/opt/build/repo/src/templates/flipping-card.js" /* webpackChunkName: "component---src-templates-flipping-card-js" */),
  "component---src-templates-quick-offer-js": () => import("/opt/build/repo/src/templates/quick-offer.js" /* webpackChunkName: "component---src-templates-quick-offer-js" */),
  "component---src-templates-sliding-product-js": () => import("/opt/build/repo/src/templates/sliding-product.js" /* webpackChunkName: "component---src-templates-sliding-product-js" */),
  "component---src-templates-page-list-js": () => import("/opt/build/repo/src/templates/page-list.js" /* webpackChunkName: "component---src-templates-page-list-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

